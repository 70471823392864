'use client';

import React, { useState, useEffect, useRef } from 'react';
import styles from './Accordion.module.css';

interface AccordionProps {
    /** Class name for the wrapper. */
    className?: string;
    /** This is the children that are passed in to the accordion. This has to be AccordionItems. */
    children: React.ReactNode;
    /** This is to allow the option of only having the accordion on mobile only. */
    mobileOnly?: boolean;
    /** This is to allow the option of only displaying headings on mobile only. */
    hideHeadingsWhenDisabled?: boolean;
    /** This is to open an accordion item by default, this needs to match the index of the array items that are passed in.  */
    defaultItemIndexOpen?: number;
}

/**
 * Accordion wrapper to deal with some state and logic
 * Pass AccordionItems into this to make it work.
 * @param {*} props
 */
const Accordion = ({
    children,
    mobileOnly = false,
    hideHeadingsWhenDisabled = false,
    defaultItemIndexOpen = undefined,
    className = '',
}: AccordionProps) => {
    const [itemOpen, setItemOpen] = useState(defaultItemIndexOpen || 0); // This stores the index of which item is open
    const [windowWidth, setWindowWidth] = useState(0);
    const [enabled, setEnabled] = useState(false);
    const [windowIsLargerThanMobile, setWindowIsLargerThanMobile] = useState(false);

    const ref = useRef<HTMLUListElement>(null);

    const onLoadWindowSize = () => setWindowWidth(window.innerWidth);

    /**
     * Window width was loading incorectly whilst elements were still loading in. Solved this issue with an
     * onLoad event to get the initial window width when page has loaded.
     */
    useEffect(() => {
        window.addEventListener('load', onLoadWindowSize);
        return () => window.removeEventListener('load', onLoadWindowSize);
    }, []);

    // eslint-disable-next-line consistent-return
    useEffect(() => {
        if (mobileOnly) {
            const timeout = undefined;
            const checkWindowWidth = () => {
                if (typeof window !== 'undefined') {
                    window.clearTimeout(timeout);
                    setTimeout(() => {
                        setWindowWidth(window.innerWidth);
                    }, 150);
                }
            };

            if (typeof window !== 'undefined') {
                checkWindowWidth();
                window.addEventListener('resize', checkWindowWidth);

                return () => {
                    window.clearTimeout(timeout);
                    window.removeEventListener('resize', checkWindowWidth);
                };
            }
        }
    }, [mobileOnly]);

    useEffect(() => {
        setWindowIsLargerThanMobile(windowWidth > 767);
        setEnabled(!mobileOnly || (mobileOnly && !windowIsLargerThanMobile));
    }, [mobileOnly, windowIsLargerThanMobile, windowWidth]);

    return (
        <ul
            ref={ref}
            className={`accordion-javascript-disabled ${styles.accordion}${!enabled ? ` ${styles.accordion__disabled}` : ''} ${className}`}
        >
            {React.Children.map(children, (child, i) => {
                if (!child) {
                    return null;
                }

                // @ts-expect-error
                return React.cloneElement(child, {
                    index: i,
                    handleOpen: (item: number) => {
                        /**
                            Typically we only want to scroll to the top of the section when something opens, so it looks clean and people dont get confused
                            However when we first enter the page, we listen to the hash in the url and open the accordion item that matches the hash.
                            This means that we dont want to scroll to the top of the section, as it will be in the middle of the screen.
                            So we check if the accordion item ref is passed in, if it is, we scroll to the top of the accordion item, if not, we scroll to the top of the section.
                         */
                        // TODO: accordion item ref isn't being passed in correctly so won't work
                        // if (accordionItemRef) {
                        //     objectScrollTo(accordionItemRef.current);
                        // } else {
                        //     objectScrollTo(ref.current!);
                        // }
                        setItemOpen(item);
                    },
                    open: mobileOnly && windowIsLargerThanMobile ? true : itemOpen === i,
                    hideHeadings: !enabled && hideHeadingsWhenDisabled,
                    enabled,
                });
            })}
        </ul>
    );
};

export default Accordion;
