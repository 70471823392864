/**
 * Capitalises a string similar to css.
 * @param {string | undefined} string - The string to be capitalised.
 * @param {boolean} lower - If the string should be converted to lower case before capitalised.
 */
export const capitalize = (string, lower = false) => {
    if (!string) return '';
    if(typeof string !== 'string') return string;

    const str = lower ? string.toLowerCase() : string;

    return str.replace(/(?:^|\s|["'([{])+\S/g, (match) => match.toUpperCase());
};

export const stripHtml = (string) => {
    if (!string) return '';
    if(typeof string !== 'string') return '';

    return string.replace(/(<([^>]+)>)/gi, '');
};

export const makeSlug = (string) => string.toLowerCase().replace(/\s+/g, '-');

/**
 * Prepends a 0 to a number & formats 2 digits.
 * @param {number} number
 */
export const prependZero = (number = 0) => `0${number}`.slice(-2);

/**
 * Does what it says on the tin
 * @param {string} url
 * @returns
 */
export const replaceTrailingSlash = (url) => url.replace(/\/$/, '');
